import React from "react"
import { LocaleContext } from '../components/layout'
import SEO from "../components/seo"

const NotFoundPage = () => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if(lang.i18n[lang.locale] != undefined){

    i18n = lang.i18n[lang.locale];
   }

  return (
    <>
      <SEO title="404" keywords={[`navigo`, `garmin`]} />
    </>
  )
}

export default NotFoundPage
